import {
  AUTH_ADMIN,
  AUTH_PACKER,
  AUTH_PICKER,
  AUTH_PRODUCT_MANAGER,
  AUTH_QUALITY_ENGINEERING,
  AUTH_SHIFT_LEAD,
  AUTH_SPV_HUB,
} from 'constant/roles'
import { AUTH_HO_ASTRO_KITCHEN_MANAGER, AUTH_HO_ASTRO_KITCHEN_STAFF } from 'middleware/privateRoute'
import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import DynamicRouteHandler from './DynamicRoutingHelper'
import { PoolingMapRolesRBAC } from '../pages/PoolingMap/rbac'

const CreateOrder = lazy(() => import('../pages/CreateOrder'))
const DriverMonitoring = lazy(() => import('../pages/DriverMonitoring'))
const PoolingMap = lazy(() => import('../pages/PoolingMap'))

type NamedRoutesType =
  | 'CreateOrderRouter'
  | 'DriverMonitoringRouter'
  | 'PoolingMapRouter'
  | 'NewOrderRouter'
type PathListsType =
  | '/dashboard/create-order'
  | '/dashboard/driver-monitoring'
  | '/dashboard/pooling-map'
  | '/dashboard/order/:status'
  | '/dashboard/pooling-map'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const CreateOrderRouter: RouterInstanceType = {
  name: 'CreateOrderRouter',
  Component: CreateOrder,
  path: '/dashboard/create-order',
  routeType: 'PRIVATE',

  allowedRoles: [AUTH_HO_ASTRO_KITCHEN_STAFF, AUTH_HO_ASTRO_KITCHEN_MANAGER],
  isDashboard: true,
}

const DriverMonitoringRouter: RouterInstanceType = {
  name: 'DriverMonitoringRouter',
  Component: DriverMonitoring,
  path: '/dashboard/driver-monitoring',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
    'AUTH_HUB_SHIFT_LEAD',
    'AUTH_HUB_SPV',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_HO_SUPPLY_CHAIN',
    'AUTH_SPV_HUB',
    'AUTH_SHIFT_LEAD',
  ],
  isDashboard: true,
}

const NewOrderRouter: RouterInstanceType = {
  name: 'NewOrderRouter',
  Component: DynamicRouteHandler,
  path: '/dashboard/order/:status',
  routeType: 'PRIVATE',
  allowedRoles: [
    /* TODO */
    AUTH_PACKER,
    AUTH_SHIFT_LEAD,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_QUALITY_ENGINEERING,
    AUTH_SPV_HUB,
    AUTH_PICKER,
    AUTH_PRODUCT_MANAGER,
  ],
  isDashboard: true,
}
const PoolingMapRouter: RouterInstanceType = {
  name: 'PoolingMapRouter',
  Component: PoolingMap,
  path: '/dashboard/pooling-map',
  routeType: 'PRIVATE',
  allowedRoles: [AUTH_PACKER, AUTH_ADMIN],
  rbacPageID: PoolingMapRolesRBAC.pageID,
  isDashboard: true,
}

const OrderRouter = [CreateOrderRouter, DriverMonitoringRouter, NewOrderRouter, PoolingMapRouter]

export const OrderRouterDetail = generateRouterDetail(OrderRouter)

export const OnGoingRoutes = {
  newOrder: '/dashboard/order/new-order',
  upcomingOrder: '/dashboard/order/upcoming-order',
  backToHub: '/dashboard/order/back-to-hub',
  picking: '/dashboard/order/picking',
  packing: '/dashboard/order/packing',
  onDelivery: '/dashboard/order/on-delivery',
  waitingConfirmation: '/dashboard/order/waiting-confirmation',
  readyForDelivery: '/dashboard/order/ready-for-delivery',
}

export default OrderRouter
