import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetProductListRequestParamsType = {
  location_ids?: string
  category_ids?: string
  brand_ids?: string
  min_price?: string
  max_price?: string
  is_price_zero?: boolean
  sku_number?: string
  product_name?: string
  product_ids?: string
  order_by?: 'product_id' | 'price' | 'cogs'
  order_direction?: 'asc' | 'desc'
  page_size?: number
  cursor?: string
}

export type ProductDataType = {
  id: number
  name: string
  sku_number: string
  image_url: string
  grammation: string
  cogs: string
  min_price: string
  max_price: string
  active_locations: string[]
  last_updated_at: string
}

export type GetProductListResponseType = {
  data: ProductDataType[]
  pagination: {
    total_elements: number
    prev_cursor: string
    next_cursor: string
  }
  error: {
    message: string
    code: number
    status: boolean
  }
}

export const getProductList = (params: GetProductListRequestParamsType) =>
  axiosInstanceApiGateway.Get<GetProductListResponseType, true>({
    url: '/pricing-engine/internal/v1/products',
    params: {
      ...params,
      order_by: params.order_by || 'product_id',
      order_direction: params.order_direction || 'desc',
    },
  })

export type DownloadProductsResponseType = {
  data: {
    file_url: string
  }
  error: {
    message: string
    code: number
    status: boolean
  }
}

export const downloadProducts = (ids: number[]) =>
  axiosInstanceApiGateway.Get<DownloadProductsResponseType, true>({
    url: '/pricing-engine/internal/v1/products/download',
    params: { ids: ids.join(',') },
  })

// New types for product detail
export type ProductMarginType = {
  margin_min: number
  margin_target: number
}

export type ProductPriceDetailType = {
  location_id: number
  location_name: string
  price: string
  is_on_review: boolean
}

export type ProductDetailDataType = {
  id: number
  name: string
  sku: string
  image_url: string
  grammation: string
  cogs: string
  updated_at: string
  het_min: string
  het_max: string
  margins: ProductMarginType[]
  price_details: ProductPriceDetailType[]
}

export type GetProductDetailResponseType = {
  data: ProductDetailDataType
  error: {
    message: string
    code: number
    status: boolean
  }
}

// New getProductDetail function
export const getProductDetail = (id: number) =>
  axiosInstanceApiGateway.Get<GetProductDetailResponseType, true>({
    url: `/pricing-engine/internal/v1/products/${id}`,
  })
