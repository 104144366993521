import { createAsyncThunk } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import { AppDispatchType } from 'store'
import { locationTypeOptions } from 'constant/locationType'
import {
  getMPStaffListAPI,
  putMPStaffStatusAPI,
  getTerminationReasonsAPI,
  putTerminationMPStaffAPI,
  putRejoinMPStaffAPI,
  getVehicleTypeAPI,
  getEmploymentTypeAPI,
  getStaffVendorListAPI,
  getDepartementListPI,
  getLocationListAPI,
  getStaffRoleListAPI,
  createMPStaffAPI,
  putMPStaffAPI,
  exportMPStaffAPI,
  type MPStaffRequestPayloadType,
  type PutResignationPayloadType,
  type FetchLocationListRequestType,
  type PutMPStaffRequestType,
  type FetchMPStaffQueryType,
} from 'features/Enterprise/Account/services'

import {
  sliceName,
  setLoading,
  setStaffList,
  setUpdateStaffStatus,
  setResignationReasons,
  setVehicleType,
  setEmploymentType,
  setStaffVendorList,
  setDepartementList,
  setLocationList,
  setRolesList,
  setQuery,
  setAddEditData,
} from './mpStaffSlice'

export const actSetQuery = (query: FetchMPStaffQueryType) => async (dispatch: AppDispatchType) => {
  await dispatch(setQuery(query))
  dispatch(fetchMPStaffList())
}

export const fetchMPStaffList = createAsyncThunk(
  `${sliceName}/fetchStaffList`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true))

      const {
        mpStaff: { query },
      } = getState() as StoreStateType

      const { data } = await getMPStaffListAPI({
        location_types: query?.locationType
          ? [query.locationType]
          : locationTypeOptions?.map((x) => x.location_type),
        location_ids: query?.locationId ? [query.locationId] : [],
        page_index: Number(query?.pageIndex || 1),
        page_size: Number(query?.pageSize || 10),
        name: query?.name?.replaceAll('%20', ' '),
        roles: query?.role ? [`AUTH_${query?.role}`] : [],
        module: {
          bank_account: true,
          department_location: true,
          staff_attributes: true,
          staff_vendor: true,
          termination_status: true,
          vehicle: true,
        },
      })

      dispatch(setStaffList(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const putMPStaffStatus = createAsyncThunk(
  `${sliceName}/putDriverStatus`,
  async ({ id, isAvailable }: { id: number; isAvailable: boolean }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await putMPStaffStatusAPI(id, isAvailable)
      dispatch(setUpdateStaffStatus({ id, status: isAvailable ? 'Available' : 'Not Available' }))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchResignationReasons = createAsyncThunk(
  `${sliceName}/fetchResignationReasons`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getTerminationReasonsAPI()

      dispatch(setResignationReasons(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const putTerminationMPStaff = createAsyncThunk(
  `${sliceName}/putTerminationMPStaff`,
  async (
    {
      staffId,
      staffName,
      payload,
    }: { staffId: number; staffName: string; payload: PutResignationPayloadType },
    { dispatch },
  ) => {
    try {
      await putTerminationMPStaffAPI(staffId, payload)
      toastSuccess(`${staffName} berhasil terupdate`)
      dispatch(fetchMPStaffList())
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const putRejoinMPStaff = createAsyncThunk(
  `${sliceName}/putRejoinMPStaff`,
  async (
    {
      staffId,
      staffName,
      payload,
    }: { staffId: number; staffName: string; payload: PutResignationPayloadType },
    { dispatch },
  ) => {
    try {
      await putRejoinMPStaffAPI(staffId, payload)
      toastSuccess(`${staffName} berhasil terupdate`)
      dispatch(fetchMPStaffList())
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchVehicleType = createAsyncThunk(
  `${sliceName}/fetchVehicleType`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getVehicleTypeAPI()
      dispatch(setVehicleType(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchEmploymentType = createAsyncThunk(
  `${sliceName}/fetchEmploymentType`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getEmploymentTypeAPI()
      dispatch(setEmploymentType(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchStaffVendorList = createAsyncThunk(
  `${sliceName}/fetchStaffVendorList`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getStaffVendorListAPI()
      dispatch(setStaffVendorList(data?.data?.data?.data || []))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchDepartementList = createAsyncThunk(
  `${sliceName}/fetchDepartementList`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getDepartementListPI()
      dispatch(setDepartementList(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchLocationList = createAsyncThunk(
  `${sliceName}/fetchLocationList`,
  async (params: FetchLocationListRequestType, { dispatch }) => {
    try {
      const { data } = await getLocationListAPI(params)
      dispatch(setLocationList(data.content))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchMPStaffRoleList = createAsyncThunk(
  `${sliceName}/fetchMPStaffRoleList`,
  async (locationType: string, { dispatch }) => {
    try {
      const { data } = await getStaffRoleListAPI({ locationType })
      dispatch(setRolesList(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const createMPStaff = createAsyncThunk(
  `${sliceName}/createMPStaff`,
  async (
    {
      payload,
      handleCloseDrawer,
    }: { payload: MPStaffRequestPayloadType; handleCloseDrawer: () => void },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      await createMPStaffAPI(payload)
      await dispatch(fetchMPStaffList())
      dispatch(setAddEditData({ isOpen: false }))
      toastSuccess('Data MP Staff baru berhasil dicreate')
      handleCloseDrawer()
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const editMPStaff = createAsyncThunk(
  `${sliceName}/editMPStaff`,
  async (
    {
      payload,
      handleCloseDrawer,
    }: { payload: PutMPStaffRequestType; handleCloseDrawer: () => void },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      await putMPStaffAPI(payload)
      await dispatch(fetchMPStaffList())
      dispatch(setAddEditData({ isOpen: false }))
      toastSuccess('Data MP Staff berhasil diupdate')
      handleCloseDrawer()
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const exportMPStaff = createAsyncThunk(
  `${sliceName}/exportMPStaff`,
  async (locationType: string, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await exportMPStaffAPI(locationType)
      saveAs(data.data.file_url)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
