import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

type NamedRoutesType = 'PricingEngineRouter'
type PathListsType = '/dashboard/pricing-engine/:page'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

export const subMenus = [
  {
    name: 'Overview',
    id: 'pricing-engine-overview',
    link: '/dashboard/pricing-engine/overview',
    location_type: false,
    allow: [],
  },
  {
    name: 'Analytics',
    id: 'pricing-engine-analytics',
    link: '/dashboard/pricing-engine/analytics',
    location_type: false,
    allow: [],
  },
  {
    name: 'Product List',
    id: 'pricing-engine-products',
    link: '/dashboard/pricing-engine/products',
    location_type: false,
    allow: [],
  },
  {
    name: 'Request List',
    id: 'pricing-engine-requests',
    link: '/dashboard/pricing-engine/requests',
    location_type: false,
    allow: [],
  },
  {
    name: 'Pricing History',
    id: 'pricing-engine-history',
    link: '/dashboard/pricing-engine/history',
    location_type: false,
    allow: [],
  },
]

const PRIVATE_ROUTE_TYPE = 'PRIVATE'

const PricingEnginePages = lazy(() => import('../pages/page'))
const PricingEngineNewRequestPage = lazy(() => import('../pages/NewRequest'))

export const PricingEngineProductRouter: RouterInstanceType = {
  name: 'PricingEngineRouter',
  Component: PricingEnginePages,
  path: '/dashboard/pricing-engine/:page',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  // rbacElementIDList: [],
}

export const PricingEngineNewRequestRouter: RouterFeatureInterface<
  'PricingEngineNewRequestRouter',
  '/dashboard/pricing-engine/requests/new'
> = {
  name: 'PricingEngineNewRequestRouter',
  Component: PricingEngineNewRequestPage,
  path: '/dashboard/pricing-engine/requests/new',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  // rbacElementIDList: [],
}

const PricingEngineRouter = [PricingEngineProductRouter, PricingEngineNewRequestRouter]

export const PricingEngineRouterDetail = generateRouterDetail(PricingEngineRouter)

export default PricingEngineRouter
